import React from 'react';

import classes from './_Input.module.scss';
import {Form} from 'react-bootstrap';

const Input = (props) => {
    let inputElement = null;

    const InputClasses = [classes.InputElement];

if(!props.invalid && props.touched){
    InputClasses.push(classes.Invalid);
}

    switch(props.elementType){
        case('input'):
        inputElement = <Form.Control {...props.elementConfig}
         className={InputClasses.join(' ')} 
          value={props.value}
           onChange={props.changed}
           />
       break;

    case('textarea'):
    inputElement = <Form.Control as="textarea" 
    className={InputClasses.join(' ')}
     {...props.elementConfig}
      value={props.value} onChange={props.changed} />;
    break;

    case('select'):
    inputElement = (<select onChange={props.changed}
    className={InputClasses.join(' ')}
      value={props.value}>
      {props.elementConfig.options.map(option => (
          <option value={option.value} key={option.value} >
          {option.displayValue}</option>
      ))}
    </select>);
    break;

    default:
    inputElement = <input
     className={InputClasses.join(' ')} 
    {...props.elementConfig}  
    value={props.value} onChange={props.changed}/>;
    }
    return(
        <Form.Group className={classes.Input}>
        <Form.Label className={classes.Label}>{props.label}</Form.Label>
        {inputElement}
        </Form.Group>
    );
};

export default Input;