import React, { useState } from 'react'
import { graphql } from 'gatsby';
import Layout from './components/layout'
import SEO from './components/seo'
import { Form, Container, Row, Col } from 'react-bootstrap'

import './sass/_contact.scss'
import Input from './components/UI/Input/Input'
import Button from './components/UI/Button/Button'

import {checkValidity} from './shared/utility';

const InternalPage = ({ data }) => {
const [state, setState] = useState({
    controls: {
        email: {
          label:'Email',
            elementType:'input',
            elementConfig:{
                type:'email',
                placeholder:'Email Address'
            },
            value:'',
            validation: {
                required:true,
                isEmail: true
            },
            valid:false,
            touched:false
        },
        firstname: {
          label:'First name',
            elementType:'input',
            elementConfig:{
                type:'text',
                placeholder:'John'
            },value:'',
            validation: {
                required:true,
                minLength: 3
            },
            valid:false,
            touched:false
        },
        lastname: {
          label:'Last name',
            elementType:'input',
            elementConfig:{
                type:'text',
                placeholder:'Doe'
            },value:'',
            validation: {
                required:true,
                minLength: 3
            },
            valid:false,
            touched:false
        },
         phonenumber: {
          label:'Phone',
            elementType:'input',
            elementConfig:{
                type:'text',
                placeholder:'+1 234 3456 '
            },value:'',
            validation: {
                required:true,
                minLength: 10
            },
            valid:false,
            touched:false
        },
        message: {
          label:'Your message',
            elementType:'textarea',
            elementConfig:{
                rows:3
            },value:'',
            validation: {
                required:true,
                minLength: 10
            },
            valid:false,
            touched:false
        },
    }, formisValid:false,
    isSignUp: true
})

    
const inputChangeHandler = (event,controlName) => {

  const updatedControls = {
      ...state.controls,
      [controlName] : {
          ...state.controls[controlName],
          value: event.target.value,
          valid: checkValidity(event.target.value,state.controls[controlName].validation),
          touched: true
      }
  };

  let formisValid = true;
for(let inputIdentifier in updatedControls){
  formisValid = updatedControls[inputIdentifier].valid && formisValid
}

  setState({controls:updatedControls,formisValid})
// console.log(updatedControls);
  
}   

const submitHandler = (event) => {
  event.preventDefault();
} 
  const formElementArray = [];
  for(let key in state.controls){
      formElementArray.push({
          id:key,
          config:state.controls[key]
      });
  }
  let form = 
  <Form onSubmit={submitHandler}>
  {formElementArray.map(formElement => (
      <Input key={formElement.id} 
      elementConfig={formElement.config.elementConfig}
      elementType={formElement.config.elementType}
      value={formElement.config.value}
      changed={(event) => inputChangeHandler(event,formElement.id)}
      invalid={formElement.config.valid}
      touched={formElement.config.touched}
      label={formElement.config.label}
      />
  ))}

  <Button btnType="Success" disabled={!state.formisValid}>{'Submit'}</Button>
  <div class="g-recaptcha" data-sitekey="6LdNzbQUAAAAAE158HCi-8Z9IBm_T-5VfMEvE-0p"></div>

  </Form>

  return (
    <Layout navlist={data.allLinkedListJson.edges}>
      <SEO title="Contact US">
      <script src="https://www.google.com/recaptcha/api.js" async defer></script>
        </SEO>

      <Container fluid className="contact--container mt--5">
        <Row className="pb-5">
          <Col
            className="contact--form--container"
            md={{ span: 4, offset: 4 }}
          >
            <div className="text-center mt-2 mb-3">
              <h2>Contact Form</h2>
              <p>Talk is cheap, please leave a message!</p>
            </div>

            {form}

          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default InternalPage

export const query = graphql`
{
  allLinkedListJson {
    edges {
      node {
        id
        link
        label
        site_id
      }
    }
  }
}   
`