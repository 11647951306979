import React from 'react'

import classes from './Button.module.scss';
import {Button} from 'react-bootstrap';


const button = (props) => {
 return <div className={classes.button_div}>
     <Button disabled={props.disabled} 
 variant={props.variant|| 'primary'} 
 className={[classes[props.btnType]].join(' ')}
  onClick={props.clicked} >
 {props.children}
</Button>
</div>
};

export default button;